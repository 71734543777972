import { Panel } from "../../../../components/ui/Panel";
import { gridSideModalAnimationOpts, gridSideModalComponentProps } from "../../../grids/GridSideModals";
import { Animations } from "../../../util/Animations";
import ModalContext from "../../context/ModalContext";
import { ActionReportingTagsSidebar } from "./ActionReportingTagsSidebar/ActionReportingTagsSidebar_view";
import { ActionsGridModal } from "./ActionsGridModal/ActionsGridModal_view";

export const SHOW_ACTIONS_GRID_MODAL = (onAssignSuccess: (itemIds: number[]) => any, selectedItems: number[]) => {
  ModalContext.show({
    showClose: false,
    title: null,
    className: "d-flex flex-column",
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    componentProps: {
      wrapHeight: "large",
      wrapWidth: "large",
      position: "middle",
      panelProps: {
        background: Panel.PanelBackgrounds.BG_LIGHT,
        className: "h-auto min-h-100",
        hasShadow: true
      }
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    },
    content: (
      <ActionsGridModal
        onAssignSuccess={items => {
          ModalContext.hide();
          onAssignSuccess(items);
        }}
        selectedActionIds={selectedItems}
      />
    )
  });
};

export const SHOW_ACTION_TAGS_SIDEBAR = (onAssignSuccess: (itemIds: number[]) => any, selectedItems: number[]) => {
  ModalContext.show({
    showClose: true,
    title: (
      <div className="mt-6">
        <h3 className="mb-5">Select Tags</h3>
      </div>
    ),
    content: (
      <ActionReportingTagsSidebar
        onSubmit={items => {
          ModalContext.hide();
          onAssignSuccess(items);
        }}
        currentIds={selectedItems}
      />
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
