import { observer } from "mobx-react";
import React, { useState } from "react";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useParams } from "react-router-dom";
import { SingleForm } from "../../../../../pages/change/forms/singleFormModel/SingleForm_view";
import { ActionReportingTagsSidebarModel } from "./ActionReportingTagsSidebar_model";

export interface ActionReportingTagsSidebarProps {
  onSubmit: (tagIds: number[]) => void;
  currentIds: number[];
}

export const ActionReportingTagsSidebar: React.FC<ActionReportingTagsSidebarProps> = observer(
  ({ onSubmit, currentIds }) => {
    const organisationId = useCurrentOrganisationId();
    const { projectId } = useParams();
    const [model] = useState(
      () => new ActionReportingTagsSidebarModel(organisationId, +projectId, onSubmit, currentIds)
    );
    if (model.isLoading) return <PositionedSpinner />;

    return (
      <div className="container-fluid">
        <SingleForm model={model.formModel} />
      </div>
    );
  }
);
