import { action, makeObservable, observable } from "mobx";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../localization/I18n";
import { IModalContextModel } from "../../../context/IModalContext";
import ModalContext from "../../../context/ModalContext";
import { UiActionRenderers } from "../../../../uiAction/IUiAction";
import { BaseModel } from "../../../../util/BaseModel";
import { SingleFormModel } from "../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { getActionReportingFilterTagFormFields } from "./ActionReportingTagsSidebar_fields";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../services/api/v2/actions/Actions.api";

export class ActionReportingTagsSidebarModel extends BaseModel {
  actionsProvider: IActionsApi;
  modalService: IModalContextModel;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  @observable projectActionTags: FP.Entities.ITag[];
  canEdit: boolean;
  @observable.ref formModel: SingleFormModel;
  onSubmit: (tagIds: number[]) => void;
  actions = [
    {
      id: "action1",
      label: I18n.t("phrases.done"),
      onAction: ev => {
        this.submitForm();
      },
      componentProps: {
        className: "ml-auto"
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];
  @observable currentTagIds: number[];

  constructor(
    organisationId: number,
    projectId: number,
    onSubmit: (tagIds: number[]) => void,
    currentTagIds: number[]
  ) {
    super();
    makeObservable(this);
    this.actionsProvider = ActionsApi;
    this.organisationId = organisationId;
    this.modalService = ModalContext;
    this.onSubmit = onSubmit;
    this.currentTagIds = currentTagIds;
    this.projectId = projectId;
    this.loadSidebar();
    this.canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, this.organisationId, this.projectId);
  }

  submitForm = async () => {
    await this.formModel.submit();
    this.onSubmit(this.currentTagIds);
    this.modalService.hide();
  };

  @action
  getProjectActionTags = async () => {
    let res = await this.actionsProvider.getTagsByProjectId(this.organisationId, this.projectId);
    if (!res || res.isError) return;
    let k = res.payload.map(e => {
      return { ...e, label: e.text, parent: 0 };
    });
    this.projectActionTags = k as any[];
  };

  @action
  loadSidebar = async () => {
    this.setIsLoading(true);
    await this.getProjectActionTags();
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getActionReportingFilterTagFormFields(
      this.currentTagIds,
      this.projectActionTags,
      this.addTagId,
      this.removeTagId
    );
    this.formModel.actions = this.actions;
    this.setIsLoading(false);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  addTagId = (tagId: number) => {
    this.currentTagIds = [...this.currentTagIds, tagId];
  };

  @action
  removeTagId = (tagId: number) => {
    let currTags = this.currentTagIds;
    currTags.splice(this.currentTagIds.indexOf(tagId));
    this.currentTagIds = [...currTags];
  };
}
